import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import { SnackbarProvider } from 'notistack';
import React, { Fragment, Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as serviceWorker from './config/serviceWorker';
import theme, { emotionTheme } from './config/theme';
import LoggedInRoute from './features/authentication/components/LoggedInRoute';
import rootReducer from './redux/index-reducer';
import rootSaga from './redux/index-sagas';
import GoogleAnalyticsTracking from './sdk/utils/GoogleAnalyticsTracking';
import history from './sdk/utils/history';
import GlobalStyles from './ui/GlobalStyles';

// Enable Dev-tools Composer
let composeEnhancers;
if (
  process.env.NODE_ENV !== 'production' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
} else {
  composeEnhancers = compose;
}

// Start Redux + Saga
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)), // allows redux devtools to watch sagas
);
sagaMiddleware.run(rootSaga);

const LoggedInApp = React.lazy(() => import('./features/logged_in/Main'));
const LoggedOutApp = React.lazy(() => import('./features/logged_out/Main'));

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <GoogleAnalyticsTracking />
        <MuiThemeProvider theme={theme}>
          <EmotionThemeProvider theme={emotionTheme}>
            <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <CssBaseline />
                <GlobalStyles />
                <Suspense fallback={<Fragment />}>
                  <Switch>
                    <LoggedInRoute path="/u/:tab?">
                      <LoggedInApp />
                    </LoggedInRoute>
                    <Route>
                      <LoggedOutApp />
                    </Route>
                  </Switch>
                </Suspense>
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
          </EmotionThemeProvider>
        </MuiThemeProvider>
      </Router>
    </Provider>
  );
}

serviceWorker.register();

export default App;
