import {
  GET_NOTES_REQUEST,
  SET_NOTES,
  CREATE_NOTE_REQUEST,
  UPDATE_NOTE_REQUEST,
  DELETE_NOTE_REQUEST,
  DELETE_NOTE,
  ADD_NOTE,
  SET_EDITING_NOTE,
  UNSET_EDITING_NOTE,
  SET_VIEW_NOTE,
  SET_EDITING_READONLY,
  SET_NOTE,
  UNSET_CREATING_NOTE,
  SET_CREATING_NOTE,
} from './constants';
import { Note } from '../types';

export type LoginRequestPayload = {
  email: string;
  password: string;
  remember: boolean;
};

const setNotesAction = (notes: Note[]) => {
  return {
    type: SET_NOTES,
    payload: notes,
  };
};

const deleteNoteAction = (noteId: number) => {
  return {
    type: DELETE_NOTE,
    payload: noteId,
  };
};

const setNoteAction = (note: Note) => {
  return {
    type: SET_NOTE,
    payload: note,
  };
};

const addNoteAction = (note: Note) => {
  return {
    type: ADD_NOTE,
    payload: note,
  };
};

const getNotesRequestAction = () => {
  return {
    type: GET_NOTES_REQUEST,
  };
};

const createNoteRequestAction = (note: Partial<Note>) => {
  return {
    type: CREATE_NOTE_REQUEST,
    payload: note,
  };
};

const updateNoteRequestAction = (note: Note) => {
  return {
    type: UPDATE_NOTE_REQUEST,
    payload: note,
  };
};

const deleteNoteRequestAction = (noteId: number) => {
  return {
    type: DELETE_NOTE_REQUEST,
    payload: noteId,
  };
};

const setEditingNote = (note: Note) => {
  return {
    type: SET_EDITING_NOTE,
    payload: note,
  };
};

const setCreatingNote = (note: Partial<Note>) => {
  return {
    type: SET_CREATING_NOTE,
    payload: note,
  };
};

const setViewNote = (noteId: number) => {
  return {
    type: SET_VIEW_NOTE,
    payload: noteId,
  };
};

const setEditReadOnly = (readOnly: boolean) => {
  return {
    type: SET_EDITING_READONLY,
    payload: readOnly,
  };
};
const unsetEditingNote = () => {
  return {
    type: UNSET_EDITING_NOTE,
  };
};

const unsetCreatingNote = () => {
  return {
    type: UNSET_CREATING_NOTE,
  };
};

export {
  getNotesRequestAction,
  setNotesAction,
  setNoteAction,
  addNoteAction,
  deleteNoteAction,
  createNoteRequestAction,
  updateNoteRequestAction,
  deleteNoteRequestAction,
  setEditingNote,
  unsetEditingNote,
  setViewNote,
  setEditReadOnly,
  setCreatingNote,
  unsetCreatingNote,
};
