import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  createNoteApi,
  deleteNoteApi,
  getNotesApi,
  updateNoteApi,
} from '../../../sdk/api/notes';
import formatError from '../../../sdk/utils/formatError';
import {
  addNoteAction,
  createNoteRequestAction,
  deleteNoteAction,
  setNoteAction,
  setNotesAction,
  updateNoteRequestAction,
} from './actions';
import {
  CREATE_NOTE_REQUEST,
  DELETE_NOTE_REQUEST,
  GET_NOTES_REQUEST,
  SET_REQUEST_ERROR,
  SET_REQUEST_INITIAL_STATE,
  SET_REQUEST_SUCCESS,
  UPDATE_NOTE_REQUEST,
  UNSET_EDITING_NOTE,
  UNSET_CREATING_NOTE,
} from './constants';

export function* fetchNotes() {
  try {
    const notesRequest = yield call(getNotesApi);
    const notes = notesRequest.data;

    yield put(setNotesAction(notes));

    yield put({ type: SET_REQUEST_SUCCESS });
  } catch (e) {
    const error = formatError(e);
    yield put({ type: SET_REQUEST_ERROR, error });
  } finally {
    yield put({ type: SET_REQUEST_INITIAL_STATE });
  }
}

function* deleteNote(action: ReturnType<typeof deleteNoteAction>) {
  try {
    yield call(deleteNoteApi, action.payload);

    yield put(deleteNoteAction(action.payload));

    yield put({ type: SET_REQUEST_SUCCESS });
  } catch (e) {
    const error = formatError(e);
    yield put({ type: SET_REQUEST_ERROR, error });
  } finally {
    yield put({ type: SET_REQUEST_INITIAL_STATE });
  }
}

function* updateNote(action: ReturnType<typeof updateNoteRequestAction>) {
  try {
    const noteRequest = yield call(updateNoteApi, action.payload);
    const note = noteRequest.data;
    yield put(setNoteAction(note));

    yield put({ type: SET_REQUEST_SUCCESS });
  } catch (e) {
    const error = formatError(e);
    yield put({ type: SET_REQUEST_ERROR, error });
  } finally {
    yield put({ type: UNSET_EDITING_NOTE });
    yield put({ type: SET_REQUEST_INITIAL_STATE });
  }
}

function* createNote(action: ReturnType<typeof createNoteRequestAction>) {
  try {
    const noteRequest = yield call(createNoteApi, action.payload);

    yield put(addNoteAction(noteRequest.data));

    yield put({ type: SET_REQUEST_SUCCESS });
    yield put({ type: UNSET_CREATING_NOTE });
  } catch (e) {
    const error = formatError(e);
    yield put({ type: SET_REQUEST_ERROR, error });
  } finally {
    yield put({ type: SET_REQUEST_INITIAL_STATE });
  }
}

function* getNotesFlow() {
  yield takeLatest(GET_NOTES_REQUEST, fetchNotes);
}

function* deleteNoteFlow() {
  yield takeLatest(DELETE_NOTE_REQUEST, deleteNote);
}

function* updateNoteFlow() {
  yield takeLatest(UPDATE_NOTE_REQUEST, updateNote);
}

function* createNoteFlow() {
  yield takeLatest(CREATE_NOTE_REQUEST, createNote);
}

// Watcher
function* notesWatcher() {
  while (true) {
    // Fork fetchNotes task in the background
    yield all([
      getNotesFlow(),
      deleteNoteFlow(),
      createNoteFlow(),
      updateNoteFlow(),
    ]);
  }
}

export { notesWatcher };
