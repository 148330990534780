import { Note } from '../types';
import {
  CREATE_NOTE_REQUEST,
  DELETE_NOTE_REQUEST,
  GET_NOTES_REQUEST,
  UPDATE_NOTE_REQUEST,
  SET_NOTES,
  SET_REQUEST_LOADING,
  SET_REQUEST_SUCCESS,
  SET_REQUEST_ERROR,
  SET_REQUEST_INITIAL_STATE,
  DELETE_NOTE,
  SET_NOTE,
  ADD_NOTE,
  SET_EDITING_NOTE,
  UNSET_EDITING_NOTE,
  SET_VIEW_NOTE,
  SET_EDITING_READONLY,
  SET_CREATING_NOTE,
  UNSET_CREATING_NOTE,
} from './constants';
import ReactGA from 'react-ga';

export type NotesState = {
  notes: Note[];
  request: {
    requesting: boolean;
    successful: boolean;
    errors: string[];
  };
  editing: {
    note?: Note;
    readOnly: boolean;
  };
  creating: {
    note?: Note;
  };
};

export type NotesAction = {
  type:
    | typeof GET_NOTES_REQUEST
    | typeof SET_NOTES
    | typeof SET_EDITING_NOTE
    | typeof SET_CREATING_NOTE
    | typeof UNSET_CREATING_NOTE
    | typeof SET_VIEW_NOTE
    | typeof SET_EDITING_READONLY
    | typeof UNSET_EDITING_NOTE
    | typeof SET_NOTE
    | typeof ADD_NOTE
    | typeof CREATE_NOTE_REQUEST
    | typeof UPDATE_NOTE_REQUEST
    | typeof DELETE_NOTE_REQUEST
    | typeof SET_REQUEST_INITIAL_STATE
    | typeof SET_REQUEST_ERROR
    | typeof SET_REQUEST_SUCCESS
    | typeof DELETE_NOTE
    | typeof SET_REQUEST_LOADING;
  payload?: Note[] | Note | number | Partial<Note>;
  error?: Error;
};

const initialState: NotesState = {
  notes: [],
  request: {
    requesting: false,
    successful: false,
    errors: [],
  },
  editing: {
    note: undefined,
    readOnly: false,
  },
  creating: {
    note: undefined,
  },
};

const notesReducer = (state = initialState, action: NotesAction) => {
  ReactGA.event({
    category: 'Notes',
    action: action.type,
  });

  switch (action.type) {
    case SET_NOTES:
      return {
        ...state,
        notes: action.payload,
      };

    case CREATE_NOTE_REQUEST:
    case UPDATE_NOTE_REQUEST:
    case DELETE_NOTE_REQUEST:
    case GET_NOTES_REQUEST:
    case SET_REQUEST_LOADING:
      return {
        ...state,
        request: {
          requesting: true,
          successful: false,
          errors: [],
        },
      };

    case DELETE_NOTE:
      return {
        ...state,
        notes: state.notes.filter((x) => x.id !== action.payload),
      };

    case SET_NOTE:
      const updateNoteIndex = state.notes.findIndex(
        (x) => x.id === (action.payload as Note).id,
      );
      const updatedNotes = [...state.notes];
      updatedNotes[updateNoteIndex] = action.payload as Note;

      return {
        ...state,
        notes: updatedNotes,
      };

    case SET_EDITING_NOTE:
      return {
        ...state,
        editing: {
          note: action.payload,
          readOnly: false,
        },
      };

    case SET_CREATING_NOTE:
      return {
        ...state,
        creating: {
          note: action.payload,
        },
      };

    case UNSET_CREATING_NOTE:
      return {
        ...state,
        creating: initialState.creating,
      };

    case SET_VIEW_NOTE:
      return {
        ...state,
        editing: {
          note: state.notes.find((x) => x.id === action.payload),
          readOnly: true,
        },
      };

    case SET_EDITING_READONLY:
      return {
        ...state,
        editing: {
          ...state.editing,
          readOnly: action.payload,
        },
      };

    case UNSET_EDITING_NOTE:
      return {
        ...state,
        editing: initialState.editing,
      };

    case ADD_NOTE:
      return {
        ...state,
        notes: state.notes = [action.payload as Note, ...state.notes],
      };

    case SET_REQUEST_SUCCESS:
      return {
        ...state,
        request: {
          requesting: false,
          successful: true,
          errors: [],
        },
      };

    case SET_REQUEST_ERROR:
      return {
        ...state,
        request: {
          requesting: false,
          successful: false,
          errors:
            action.error &&
            state.request.errors.concat([action.error.toString()]),
        },
      };

    case SET_REQUEST_INITIAL_STATE:
      return {
        ...state,
        request: initialState.request,
      };

    default:
      return state;
  }
};

export default notesReducer;
