export const GET_NOTES_REQUEST = 'GET_NOTES_REQUEST';
export const SET_NOTES = 'SET_NOTES';
export const UPDATE_NOTE_REQUEST = 'UPDATE_NOTE_REQUEST';
export const CREATE_NOTE_REQUEST = 'CREATE_NOTE_REQUEST';
export const DELETE_NOTE_REQUEST = 'DELETE_NOTE_REQUEST';
export const SET_REQUEST_LOADING = 'SET_REQUEST_LOADING';
export const SET_REQUEST_SUCCESS = 'SET_REQUEST_SUCCESS';
export const SET_REQUEST_ERROR = 'SET_REQUEST_ERROR';
export const SET_REQUEST_INITIAL_STATE = 'SET_REQUEST_INITIAL_STATE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const SET_NOTE = 'SET_NOTE';
export const ADD_NOTE = 'ADD_NOTE';
export const SET_EDITING_NOTE = 'SET_EDITING_NOTE';
export const SET_VIEW_NOTE = 'SET_VIEW_NOTE';
export const SET_EDITING_READONLY = 'SET_EDITING_READONLY';
export const UNSET_EDITING_NOTE = 'UNSET_EDITING_NOTE';
export const UNSET_CREATING_NOTE = 'UNSET_CREATING_NOTE';
export const SET_CREATING_NOTE = 'SET_CREATING_NOTE';
