import apiClient from './apiClient';
import { Note } from '../../features/notes/types';

const NOTES_PATH = '/notes';

export const createNoteApi = (noteDto: Partial<Note>) =>
  apiClient.post(`${NOTES_PATH}`, noteDto);

export const getNotesApi = () => apiClient.get(`${NOTES_PATH}`);

export const deleteNoteApi = (noteId: number) =>
  apiClient.delete(`${NOTES_PATH}/${noteId}`);

export const updateNoteApi = (note: Note) =>
  apiClient.put(`${NOTES_PATH}/${note.id}`, note);
